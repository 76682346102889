.spinnerContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 500;
}

.leftShift {
  transform: translateX(-120px);
}

.dark {
  background-color: rgba(92, 92, 92, 0.6);
}

.light {
  background-color: rgba(255, 255, 255, 0.6);
}

.lightIcon {
  color: rgb(245, 222, 179);
}

.iconReverseSpin {
  animation: iconReverseSpinFrames 2s infinite linear;
  margin-left: -4px;
  margin-right: -2px;
}

@keyframes iconReverseSpinFrames {
  0% {
    transform: rotate(340deg);
  }

  100% {
    transform: rotate(-20deg);
  }
}

@media screen  and (max-width: 920px) {
  .spinnerContainer {
    left: 0;
  }
}
