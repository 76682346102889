.block {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 1.2em;
  color: #7e7e7e;
}

@media screen and (min-width: 920px) {
  .block {
    flex-direction: row;
    justify-content: flex-start;
    text-align: justify;
  }

  .gapped {
    margin-left: 8px;
  }
}
