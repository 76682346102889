.modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: 80vh;
  z-index: 1040;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -20%);
  /* border: 1px solid #e5e5e5; */
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  border-radius: 3px;
}

.header {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px 20px;
}

.title {
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 700;
  font-size: 1.3em;
}

.closer {
  position: absolute;
  top: 3px;
  right: 6px;
  cursor: pointer;
  line-height: 1em;
  font-size: 1.4em;
  z-index: 20;
}

.inner {
  position: relative;
  display: flex;
  width: 100%;
  max-height: 100%;
  padding-top: 30px;
  overflow: hidden;
}

.backdrop {
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
}

@media screen and (min-width: 920px) {
  .modal {
    min-width: 520px;
  }
}

@media screen and (max-width: 920px) {
  .modal {
    display: block;
    transform: unset;
    position: absolute;
    height: auto;
    /* bottom:0; */
    top: 0;
    left: 0;
    right: 0;
    margin-top: 20px;
    /* margin-bottom:20px; */
    margin-right: 20px;
    margin-left: 20px;
    border-radius: 6px;
  }
}