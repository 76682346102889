.floatingButtonContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 12px;
  bottom: 24px;
  right: 24px;
  z-index: 500;
}

.floatingButton {
  box-shadow: 2px 2px 3px #999;
  text-align: center;
  width: 56px!important;
  height: 56px!important;
}

.floatingButton:hover {
  box-shadow: 2px 3px 6px rgb(87, 87, 87);
}

.floatingButton i {
  width: 56px!important;
  height: 56px!important;
  line-height: 2.1em!important;
  font-size: 1.4em!important;
}

.colorMain {
  background-color: rgb(163, 65, 0)!important;
}

.colorGray {
  background-color: rgb(158, 158, 158)!important;
}

.colorRed {
  background-color: rgb(190, 51, 51)!important;
}

@media print {
  .floatingButtonContainer {
    display: none;
  }
}
