@import '~rsuite/dist/styles/rsuite-default.css';

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #e6e6e6;
}

.header {
  /* height: 64px; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 12px; */
  /* background-color: #26a892; */
  /* background-color: rgb(255, 112, 172);
  border-bottom: 1px solid rgb(95, 95, 95); */
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  z-index: 300;
  background-color: #ffffff;
}

.main {
  display: flex;
  flex: 1 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* padding-top: 18px;
  padding-bottom: 18px; */
  background-color: #ffffff;
}

/* .mainWrapper {
  display: flex;
  flex: 1 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #ffffff;
} */

.content {
  position: relative;
  display: flex;
  flex: 1 1;
  width: 100%;
  background-color: #fff;
  /* height: 100%; */
  /* height: calc(100% - 20px);
    margin: 10px; */
  /* background-color: #fff; */
}

.section {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.list {
  padding: 10px;
  width: 100%;
  overflow-y: auto;
}

.tilesGrid {
  width: 100%;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.tileCard {
  width: 246px;
  background-color: #f7f7f7;

  padding: 20px;
  border-radius: 10px;
  border: 1px solid #c2c2c2;
  /* box-shadow: 0 0 2px rgba(117, 117, 117, 0.8); */
}

.tileCard:hover {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6);
}

.tileCardContent {
  margin-top: 20px;
}

.tileCardContentRow {
  display: flex;
  flex-direction: row;
}

.tileCardContentRow div:last-child {
  padding-left: 10px;
}

.tileCardContentRow td:last-child {
  padding-left: 6px;
}

.tileCardBody {
  padding-left: 20px;
  padding-bottom: 20px;
}

.tileCardHeaderContent tr td:last-child {
  padding-left: 6px;
}

.creationPanel .rs-panel-body {
  padding: 0 !important;
}

/* .creationPanel .rs-panel-heading,
.creationPanel .rs-panel-body, */
.workerPanel .rs-panel-heading,
.workerPanel .rs-panel-body {
  padding: 10px !important;
}

.creationPanel .rs-panel-heading::before {
  display: none;
}

.creationTile {
  border: 1px dashed #c2c2c2;
  background-color: #ffffff;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.creationButton {
  color: #999;
  border: 1px dashed #999;
}

.creationTileCaption {
  margin-left: 10px;
  text-decoration: none;
  color: #aaa;
}

.creationTile:hover,
.creationTile:active,
.creationTile:focus {
  text-decoration: none !important;
}

.error-notification .rs-notification-item-content {
  background-color: #fde9ef !important;
  border-radius: 6px;
}

.rs-picker-menu {
  z-index: 2000 !important;
}

/*================================= Scrollbar ================================*/

/* W3C standard now only for Firefox (2021-06) */
* {
  scrollbar-width: auto;
  /* scrollbar-color: blue orange; */
}

/* For Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: rgba(229, 229, 234, 0.4);
  border-radius: 6px;
}

*::-webkit-scrollbar-thumb {
  border: 3px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #AAAAAA;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #7e7e7e;
}

/*================================ !Scrollbar! ===============================*/

@media screen and (min-width: 1024px) {
  .main {
    width: 1024px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1024px) {
  .list {
    padding-left: 10px;
    padding-right: 10px;
  }

  .tilesGrid {
    justify-content: space-around;
  }
}

@media screen and (max-width: 920px) {

  /* rsuite modal width fix for small screens to prevent modal body scrolling */
  .rs-modal-sm {
    width: 100% !important;
  }
}

@media screen and (max-width: 781px) {
  .tilesGrid {
    justify-content: space-between;
  }

  .tileCard {
    width: 48%;
  }
}

@media screen and (max-width: 534px) {
  .tilesGrid {
    justify-content: center;
  }

  .tileCard {
    width: 100%;
  }
}

@media print {
  * {
    color: black;
    background: white;
  }

  .main {
    overflow: visible !important;
  }

  .header {
    box-shadow: unset;
  }

  @page :left {
    margin: 1cm;
  }

  @page :right {
    margin: 1cm;
  }

  /* The first page of a print can be manipulated as well */
  @page :first {
    margin: 1cm 2cm;
  }
}