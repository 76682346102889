.container {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding-top: 10px;
  /* height: 52px; */
  background-color: #fff;
  /* z-index: 100; */
}

.control {
  /* position: fixed; */
  display: flex;
  gap: 6px;
  padding: 8px;
  width: 100%;
  /* box-shadow: 0 5px 3px 0 rgba(0,0,0,0.3); */
}

.pusher {
  margin-top: 36px;
}

@media screen and (max-width: 920px) {
  .control {
    flex-wrap: wrap;
  }
}

@media print {
  .container {
    display: none;
  }
}
