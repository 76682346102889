.controlWrapper {
  width: 100%;
  margin-top: 2px;
}

.searchWrapper {
  width: 100%;
}

.buttonsWrapper {
  display: flex;
  margin-top: 10px;
  gap: 10px;
}

.buttonsContainer {
  flex: 0 0 auto;
}