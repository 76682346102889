.dashboardTile {
    display: inline-block;
    position: relative;
    width: 300px;
    height: 100px;
    text-align: center;
    background-color: rgba(245, 245, 245, 0.9)
}

.dashboardTile:hover {
    box-shadow: 0 0 6px 5px rgba(0, 0, 0, 0.3);
}

.tileCounter {
    font-weight: 600;
    font-size: 1.2em;
}

.text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100px;
    width: 300px;
    /* padding: 20px; */
    z-index: 100;
}

/* .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100px;
    width: 300px;
    z-index: 100;
} */

.text div,
.text div:hover,
.text h4 {
    font-size: 1.6em;
    text-decoration: none;
    /* color: #ffffff;
    background-color: rgba(0, 0, 0, 0.4); */
    color: #1f1f1f;
    /* background-color: rgba(241, 241, 241, 0.9); */
}

.text h4 {
    padding-top: 8px;
}

.text div {
    padding-bottom: 8px;
}

.bg {
    width: 300px;
    height: 200px;
    position: absolute;
    overflow: hidden;
    top: 0;
    bottom: 0;
}

.bgBox {
    width: 400px;
    height: 300px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: skewY(15deg);
}

.bg img {
    width: 300px;
    height: 200px;
    object-fit: contain;
    /* clip-path: polygon(100% 44%, 0% 100%, 100% 100%); */
    /* clip-path: polygon(100% 0, 0% 100%, 100% 100%); */

    -webkit-filter: grayscale(1) opacity(0.8);
    filter: grayscale(1) opacity(0.8);

    /* -webkit-filter: grayscale(1) opacity(0.7);
    filter: grayscale(1) opacity(0.7); */
}
