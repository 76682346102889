.box {
  padding-top: 10px;
  padding-bottom: 10px;
}

.trigger {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

.hidden {
  display: none;
}
