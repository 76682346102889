.label {
  display: inline-block;
}

.labelCaption {
  padding-top: 7px;
  padding-bottom: 5px;
  padding-left: 10px;
  cursor: text;
}

.createBtn {
  padding-top: 5px!important;
  padding-bottom: 5px!important;
}

.labelEdit {
  box-sizing: border-box;
  padding-top: 7px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 4px;
  border: none;
}

.labelEdit,
.labelEdit:hover,
.labelEdit:focus,
.labelEdit:active,
.labelEdit:focus-visible {
  outline: none;
  border-bottom: 1px solid #1675e0;
}

.saver,
.canceler {
  z-index: 20;
  cursor: pointer;
  font-size: 1.2em!important;
  padding-left: 10px;
}

.saver {
  color: rgb(87, 173, 1);
}

.canceler {
  color:rgb(209, 0, 0);
}

.remover {
  display: none!important;
  font-size: 1.2em!important;
  margin-left: 10px;
}

.labelContainer:hover .remover {
  display: inline-block!important;
}

.remover:hover {
  color: rgb(209, 0, 0);
  cursor: pointer;
  z-index: 20;
}

@media screen and (max-width: 920px) {
  .remover {
    display: inline-block!important;
  }
}
