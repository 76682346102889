.sideNavBlock {
  position: relative;
  height: 100%;
  width: 240px;
  /* border-right: 1px solid rgb(199, 199, 199); */
  /* margin: 10px; */
}

.sideNav {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  height: 100%;
  padding: 20px;
  /* margin: 20px; */
  font-size: 1.3em;
  /* background-color: #ffffff; */
}

.versionContainer {
  position: absolute;
  left: 20px;
  bottom: 8px;
  font-size: .8em;
  font-weight: 400;
  z-index: 10;
}

/* .navLinks {
  
} */

.link,
.activeLink {
  text-decoration: none;
}

.link:hover,
.activeLink:hover {
  text-decoration: none;
}

.link:active,
.activeLink:active {
  text-decoration: none;
}

.link:focus,
.activeLink:focus {
  text-decoration: none;
}

.link {
  display: block;
  width: 100%;
  color: rgb(110, 110, 110);
}

.activeLink {
  color: rgb(163, 65, 0);
}

.activeLink:hover {
  color: rgb(163, 65, 0);
}

.activeLink:active {
  color: rgb(163, 65, 0);
}

.activeLink:focus {
  color: rgb(163, 65, 0);
}

.clientSection {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding-bottom: 20px;
  margin-bottom: 10px;
  font-size: 1em!important;
  border-bottom: 1px solid #e5e5ea;
  z-index: 20;
}

.longButton {
  width: 100%;
}

.clientCaption {
  font-style: italic;
  font-size: 0.9em;
}

@media screen and (max-width: 920px) {
  .sideNavBlock{
    display: none;
  }
}

@media print {
  .sideNavBlock {
    display: none;
  }
}