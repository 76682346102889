.headerWrapper {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.userSection {
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid gray;
}

.headerImg {
  height: 54px;
  object-fit: contain;
}

.cleanLink {
  color: inherit;
  text-decoration: none;
}

.clientSection {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  gap: 10px;
  overflow: hidden;
}

.printHeader {
  display: none;
}

.leftSection,
.rightSection {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 8px;
  z-index: 10;
}

.rightSection {
  margin-right: 10px;
}

.appsSection {
  display: flex;
  align-items: center;
  border: 1px solid #1675e0;
  border-radius: 20px;
  padding: 7px 10px 7px;
}

.appsSection a,
.appsSection a:hover,
.appsSection a:focus {
  text-decoration: none;
}

.menuButton {
  display: none;
}

@media screen and (min-width: 1024px) {
  .headerWrapper {
    width: 1024px;
    margin: 0 auto;
  }

  .rightSection {
    float: right;
  }
}

@media screen and (max-width: 920px) {
  .menuButton {
    display: block;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media print {
  .headerWrapper,
  .clientSection {
    display: none;
  }

  .printHeader {
    display: block;
  }
}
